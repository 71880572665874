import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Trix from "trix" // import "@rails/actiontext"
import TrixUploader from "./trix_uploader";

// Simplified: https://github.com/basecamp/trix/blob/main/src/trix/core/helpers/dom.js#L160
const makeElement = (tagName, attributes = {}) => {
  const element = document.createElement(tagName);

  for (const key in attributes) {
    element.setAttribute(key, attributes[key]);
  }

  return element;
}

// https://github.com/basecamp/trix/blob/main/src/trix/core/helpers/dom.js#L134
const removeNode = (node) => node?.parentNode?.removeChild(node);

Trix.config.input.pickFiles = function(callback){
  const fileInputId = `trix-file-input-${Date.now().toString(16)}`;
  const input = makeElement("input", { type: "file", multiple: false, hidden: true, accept: "image/*", id: fileInputId });

  input.addEventListener("change", () => {
    callback(input.files);
    removeNode(input)
  });

  removeNode(document.getElementById(fileInputId));
  document.body.appendChild(input);
  input.click()
};

Trix.config.attachments.preview.caption = {
  name: false,
  size: false
};

addEventListener("trix-initialize", function(event) {
  if (event.target.dataset.attachments !== "enabled") {
    const fileButton = event.target.toolbarElement.querySelector("[data-trix-button-group=file-tools]");

    if (fileButton)
      fileButton.remove();
  }
});

addEventListener("trix-file-accept", function(event) {
  const isAnImageFile = function(file) {
    return file && file["type"].split("/")[0] === "image";
  };

  if (event.target.dataset.attachments !== "enabled") {
    return event.preventDefault();
  }

  if (!isAnImageFile(event.file)) {
    return event.preventDefault();
  }
});

addEventListener("trix-attachment-add", event => {
  const { attachment, target } = event

  if (attachment.file) {
    const uploader = new TrixUploader(attachment, target)
    uploader.open()
  }
})
